import UpperFooter from "./UpperFooter";
import LowerFooter from "./LowerFooter";
import styles from "./style.module.scss";

const Footer = () => {
  return (
    <footer className={styles.container}>
      <UpperFooter />
      <LowerFooter />
    </footer>
  );
};

export default Footer;
