/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import Link from "next/link";

import styles from "../style.module.scss";

export default class LowerFooter extends PureComponent {
  year = new Date().getFullYear();

  render() {
    return (
      <div className={styles.lower}>
        <div className={`inset ${styles.responsiveFlex}`}>
          <div className={styles.newYorkTopCities}>
            <nav className={`${styles.section} ${styles.officeSpace}`}>
              <Link
                href="/office-space/m/ny/new-york/635d40e4-ee2c-4144-8c95-040714888811"
                className={styles.buildings}
              >
                <a>
                  <h2>Office Space in NYC</h2>
                </a>
              </Link>
              <div className={styles.submarkets}>
                <h2 className={styles.locationSubHeading}>
                  <Link
                    href="/office-space/m/ny/new-york/manhattan/5da2ce96-5064-404b-ae7f-edc5ffbbf6a1"
                    className={styles.buildings}
                  >
                    <a data-qa="footer-manahttan-search-link">Manhattan</a>
                  </Link>
                </h2>
                <h3 className={styles.locationSubHeading}>
                  <Link
                    href="/office-space/m/ny/new-york/brooklyn/cc6bf55f-bd53-4785-8b07-12363eab96a6"
                    className={styles.buildings}
                  >
                    <a>Brooklyn</a>
                  </Link>
                </h3>
                <h3 className={styles.locationSubHeading}>
                  <Link
                    href="/office-space/m/ny/new-york/queens/f07913c0-3552-49cf-a5c3-393bd420a36a"
                    className={styles.buildings}
                  >
                    <a>Queens</a>
                  </Link>
                </h3>
              </div>
              <div className={styles.neighborhoodList}>
                <ul>
                  <li>
                    <Link href="/office-space/m/ny/new-york/chelsea/f5aa7229-00b5-42d8-9442-55f884ae1f3b">
                      <a>Chelsea</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/chinatown/15dbb4ad-33ff-4f38-8251-52b7f863d1e5">
                      <a>Chinatown</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/east-village/88d9e668-b2fa-4ebe-a54e-488328f56823">
                      <a>East Village</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/financial-district/d2d17ffb-c20b-4264-b323-7ad02ad2bec2">
                      <a>Financial District</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/flatiron-district/7d2cfc8c-a753-449f-bb19-7054112af57e">
                      <a>Flatiron District</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/garment-district/11248cda-2463-4fb8-a861-1ceaacd5d000">
                      <a>Garment District</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/greenwich-village/cc015f41-e276-4cab-b0e4-2ef6dd5ba12c">
                      <a>Greenwich Village</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/harlem/a5989c7d-9ec0-4dfa-bf25-e39aba76c441">
                      <a>Harlem</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/hudson-square/487bd69c-8f9d-4441-895b-29d1d40afc17">
                      <a>Hudson Square</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/little-italy/f48e8f71-dd6b-46ed-9575-692a7b1c801e">
                      <a>Little Italy</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/lower-east-side/82351707-ced7-4c44-aab6-f39347566197">
                      <a>Lower East Side</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/midtown/b3fdd4b3-de27-4c33-a165-f4a5e9e7d960">
                      <a>Midtown</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/noho/e6657556-6c44-47e8-b825-f61d1a35896e">
                      <a>NoHo</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/soho/94e3c05e-07b1-4ec2-9ce6-52de63e49ab0">
                      <a>Soho</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/tribeca/9fc54ad2-a4ac-44b3-bb7b-59cbbc030cc6">
                      <a>Tribeca</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/union-square/d6a095c6-cb75-4667-9b78-7ca8528498ee">
                      <a>Union Square</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/upper-east-side/f1ab3eed-b6cc-4180-b761-ee7f052f2084">
                      <a>Upper East Side</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/ny/new-york/upper-west-side/3c10e70a-19ab-4d95-ad25-a91f38fadbbd">
                      <a>Upper West Side</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
            <nav className={`${styles.section} ${styles.officeSpace}`}>
              <Link
                href="/office-space/m/tx/houston/64288440-ae17-4093-94ee-95089cc895ca"
                className={styles.buildings}
              >
                <a>
                  <h2>Office Space in Houston</h2>
                </a>
              </Link>
              <div className={styles.submarkets}>
                <h2 className={styles.locationSubHeading}>
                  <Link
                    href="/office-space/m/tx/houston/downtown/756e3455-7860-46ed-8107-b3db98f07a3c"
                    className={styles.buildings}
                  >
                    <a>Downtown</a>
                  </Link>
                </h2>
              </div>
              <div className={styles.neighborhoodList}>
                <ul>
                  <li>
                    <Link href="/office-space/m/tx/houston/eado/9968bc2b-df69-4abb-97fe-b8caf11caa39">
                      <a>EaDo</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/energy-corridor/094cdbaf-42d8-4b56-910c-4f3fd37dbdf5">
                      <a>Energy Corridor</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/greenspoint/0a68a7f5-e244-4eac-9cff-c2cfdfb8d654">
                      <a>Greenspoint</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/greenway-plaza/b425aae3-e01d-4a7e-9ce3-1a1904bff046">
                      <a>Greenway Plaza</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/houston-heights/3963b752-cc7b-444f-b43b-959ad2fd94fc">
                      <a>Houston Heights</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/memorial/bb2c5f46-5ab9-47e7-896a-df5b7d3dd550">
                      <a>Memorial</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/midtown/cf3e99d4-ac6c-4190-8a7e-02a97948399d">
                      <a>Midtown</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/montrose/b81a981a-9d71-4e0d-bcb5-7855e47b909e">
                      <a>Montrose</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/southwest-houston/b3f93eed-277f-4ae4-8d7f-cfbd775f6042">
                      <a>Southwest Houston</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/spring/411f19bf-574a-4a1f-b149-1eb5e6aa3b89">
                      <a>Spring</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/spring-branch/369114bc-64f1-48ff-9e68-195946bebbf8">
                      <a>Spring Branch</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/sugar-land/a4e3cbed-78ef-43a4-8acc-09e84134ee0d">
                      <a>Sugar Land</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/medical-center/4a6bc27b-841e-4ef9-a82f-85ec173e07ce">
                      <a>Medical Center</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/uptown/abb341ef-00e7-4e25-9edb-72613a3a5101">
                      <a>Uptown</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/west-university/a4199264-036e-4d92-a93b-f92fe4dfa66b">
                      <a>West University</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/office-space/m/tx/houston/westchase/373c66be-545a-485b-835c-bf265b21ebe6">
                      <a>Westchase</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
            <nav className={`${styles.section} ${styles.footerBottomWrapper}`}>
              <div className={styles.footerBottomCities}>
                <h2>Top Cities</h2>
                <div className={styles.submarkets}>
                  <h3 className={styles.locationSubHeading}>
                    <a
                      href={`${process.env.NEXT_PUBLIC_MIYAGI_LEGACY_URL}/buildings/`}
                    >
                      All Buildings
                    </a>
                  </h3>
                </div>
                <div className={styles.neighborhoodList}>
                  <ul>
                    <li>
                      <Link href="/office-space/m/ga/atlanta/f6de063c-fa5e-4498-bfd4-6e160dcc27d4">
                        <a>Atlanta</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/m/tx/austin/54287fd7-b09e-459e-a0e0-7490d5b78d92">
                        <a>Austin</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/m/ma/boston/c0c8be7a-301d-4751-b6fa-58814ff69dd4">
                        <a>Boston</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/nc/charlotte">
                        <a>Charlotte</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/m/il/chicago/22d2201f-5a40-461e-9c5c-0a9cbb44fa67">
                        <a>Chicago</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/m/tx/dallas/a0377b04-7a73-4d77-8464-46f98520f9ab">
                        <a>Dallas</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/m/co/denver/ddf4d9ba-e436-46c5-8c21-78bc8e65ecca">
                        <a>Denver</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/fl/fort-lauderdale">
                        <a>Fort Lauderdale</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/m/ca/los-angeles/a40e5fb3-5dea-4108-80c3-fa658de997d8">
                        <a>Los Angeles</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/m/fl/miami/274fadb2-c606-49c9-b358-b0ba32ba4930">
                        <a>Miami</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/mn/minneapolis">
                        <a>Minneapolis</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/m/tn/nashville/f9f0b256-6109-471d-af78-cdde1e4b1703">
                        <a>Nashville</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/fl/palm-beach">
                        <a>Palm Beach</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/m/pa/philadelphia/0977a73d-aa4e-4e6f-b015-1d0c8c7b58d3">
                        <a>Philadelphia</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/pa/pittsburgh">
                        <a>Pittsburgh</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/ca/san-diego">
                        <a>San Diego</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/m/ca/san-francisco/39a3984e-a8ef-44d6-9c29-654071a22b3f">
                        <a>San Francisco</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/office-space/m/dc/washington/58802ad1-d355-4e4a-a770-bba97ac9b1d3">
                        <a>Washington, D.C.</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            <footer className={styles.footerBottomLegal}>
              <span className={styles.copyright}>
                &copy; {this.year} SquareFoot
              </span>{" "}
              <span className={styles.allRightsReserved}>
                All rights reserved
              </span>
              <div className={styles.legalLinks}>
                <a className={styles.legalLink} href="/terms">
                  Terms
                </a>
                <a className={styles.legalLink} href="/privacy">
                  Privacy
                </a>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}
