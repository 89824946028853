import { useState } from "react";
import PropTypes from "prop-types";

import SignInModal from "components/componentLibrary/Modals/SignInModal";

import HeaderLogo from "./Logo";
import styles from "./style.module.scss";

function LoggedOutHeader({ className }) {
  const [showSignInModal, setShowSignInModal] = useState(false);

  return (
    <header className={`inset-layout ${styles.container} ${className}`}>
      <HeaderLogo />
      <div className={styles.navigationContainer}>
        <input
          type="checkbox"
          id="toggle-header-menu"
          className={styles.toggleButton}
        />
        <a href="tel:+19179941062" className={styles.phoneButton}>
          <span className="icon-phone" />
        </a>
        {/* There seems to be something wrong with ESlint that it's not picking up the htmlFor, but the recommended approach for two labels is also to disable the line https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/7d5511d41a24f4291609c4542a7b659827011425/docs/rules/label-has-associated-control.md#case-i-have-two-labels-for-the-same-input */}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          className={styles.burgerButton}
          htmlFor="toggle-header-menu"
          data-qa="homepage-hamburger-menu"
        >
          ☰
        </label>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={styles.closeButton} htmlFor="toggle-header-menu">
          ✕
        </label>
        <nav className={styles.nav}>
          <ul>
            <li>
              <a
                href={`${process.env.NEXT_PUBLIC_MIYAGI_LEGACY_URL}/office-space-calculator`}
              >
                {/* onClick={this.onItemClicked}> */}
                Smart Tools
              </a>
            </li>
            <li>
              <button
                onClick={() => setShowSignInModal(true)}
                type="button"
                data-qa="homepage-sign-in-button"
              >
                Sign In
              </button>
            </li>
            <li className={styles.contact}>
              <a href="tel:+19179941062">
                <span className="icon-phone" />
                <span>(917) 994-1062</span>
              </a>
            </li>
            <li>
              <a
                href="/leasopedia/#office-search-guide"
                /* onClick={this.onItemClicked} */
                className={styles.quickStart}
              >
                Quick Start Guide
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {showSignInModal && (
        <SignInModal onClose={() => setShowSignInModal(false)} />
      )}
    </header>
  );
}

LoggedOutHeader.propTypes = {
  className: PropTypes.string,
};

LoggedOutHeader.defaultProps = {
  className: null,
};

export default LoggedOutHeader;
