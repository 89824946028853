import PropTypes from "prop-types";
import { useCurrentUser } from "hooks";

import Footer from "./Footer";
import LoggedInHeader from "./Header/LoggedInHeader";
import LoggedOutHeader from "./Header/LoggedOutHeader";

function Layout({ children }) {
  // This is an example of useCurrentUser for the loggedInHeader
  const { currentUser } = useCurrentUser();

  return (
    <>
      {currentUser ? <LoggedInHeader /> : <LoggedOutHeader />}
      {children}
      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
