/* eslint-disable graphql/template-strings */
import { gql } from "@apollo/client";
import { useState, useEffect } from "react";
import { initializeApollo } from "init/apolloClient";

export const ACTIVE_CAMPAIGN_QUERY = gql`
  query {
    activeCampaign @client {
      id
      uuid
      identifier
      displayName
      tours {
        id
      }
      renters {
        id
        email
        phone
        lastName
        firstName
        displayName
      }
      userInCampaign
      currentActiveRequirements(setByBroker: true) {
        id
        maxPsf
        minPsf
        staticMapUrl
        maxSquareFeet
        minSquareFeet
      }
    }
  }
`;

/*
 * Checks user exists in cache and returns if existing, otherwise a query will be made to squapi
 */
const useActiveCampaign = () => {
  const apolloClient = initializeApollo();

  const [activeCampaignQuery, setActiveCampaignQuery] = useState({
    loading: true,
    data: undefined,
    error: undefined,
  });

  useEffect(() => {
    async function watchActiveCampaign() {
      /*
       * Watches for changes to activeCampaign on apollo cache.
       * Because apolloClient is on the node server and on the client,
       * reactive variables don't work as expected according to the apollo documentation.
       * Subscribing to the activeCampaign on cache is the approach implemented below
       */
      const observableQuery = apolloClient
        .watchQuery({
          query: ACTIVE_CAMPAIGN_QUERY,
          fetchPolicy: "cache-only",
        })
        .subscribe({
          error: (error) => setActiveCampaignQuery({ error }),
          next: (res) =>
            setActiveCampaignQuery({ data: res.data, loading: res.loading }),
        });
      /*
       * Rids of cache subscription on unmount of this hook
       */
      return function cleanup() {
        observableQuery.cleanup();
      };
    }
    watchActiveCampaign();
    // disabling because empty array is intended for component did mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return activeCampaignQuery;
};

export default useActiveCampaign;
