import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

const LoadingDots = ({ children }) => (
  <>
    {children}
    <span className={styles.first}>.</span>
    <span className={styles.second}>.</span>
    <span className={styles.third}>.</span>
  </>
);

LoadingDots.propTypes = {
  children: PropTypes.node,
};

LoadingDots.defaultProps = {
  children: null,
};

export default LoadingDots;
